.w-100 {
  width: 100%;
  font-family: "Proxima Nova Alt", sans-serif;
}
.nav-link {
  cursor: pointer;
}
.under {
  text-decoration: underline !important;
  color: #991403;
}
.sd-template-one {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #efefef;
  font-family: "Proxima Nova Alt", sans-serif;
  .center-loader {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .sd-t1-slider {
    // padding: 33px 33px 20px;
    padding: 20px 20px 30px 20px;
    overflow: hidden;
    .slick-slider {
      .slick-prev {
        left: 3.5vw;
        z-index: 10;
      }
      .slick-next {
        right: 3.5vw;
        z-index: 10;
      }
      .slick-dots {
        bottom: -20px;
        z-index: 2;
        li {
          margin: 0 1px;
          color: #a41503;
          button {
            opacity: 1;
            &::before {
              color: #a41503;
              font-size: 10px;

              opacity: 1;
            }
          }
        }
        .slick-active {
          button {
            white-space: nowrap;

            &::before {
              color: #ef9e1f !important;
            }
          }
        }
      }
      .slick-list {
        .slick-track {
          div {
            border: none;
            box-shadow: none;
            outline: none;
            img {
              border: none;
              box-shadow: none;
              outline: none;
            }
          }
        }
      }
    }
    // .owl-dots {
    //   border: none;
    //   .owl-dot {
    //     border: none;
    //     box-shadow: none;
    //     outline: none;
    //     span {
    //       background: #2f3193 !important;
    //     }
    //     button {
    //       outline: none;
    //       border: none;
    //       box-shadow: none;
    //       color: #991403;
    //     }
    //   }
    // }
    // .sd-fade-in {
    //   animation: fade-in 1s;
    // }
  }
  .sd-fade-in {
    animation: fade-in 1s;
  }
  .sd-t1-bottom {
    height: 55px;
    overflow: hidden;
    display: flex;
    padding-left: 60px;
    padding-right: 60px;
    border-bottom: 1px solid #d0d0d0;
    border-top: 1px solid #d0d0d0;
    align-items: center;
    justify-content: space-between;
    .sd-orange {
      width: 10.5%;
      margin: 0px;
      color: #991403;
      font-size: 13.5px;
      border-right: 1px solid #d8d8d8;
      text-align-last: center;
      font-weight: 600;
      padding-right: 10px;
    }

    .sd-marquee-text {
      font-size: 15px;
      color: #000000 !important;
      width: 80%;
      font-weight: 500;
      cursor: pointer;
    }

    div {
      align-items: center;
      text-align: -webkit-center;
      button {
        padding: 6px 13px;
        font-size: 14px;
        font-weight: 500;
        color: white;
        background-color: #991403;
        border: none;
        display: flex;
        align-self: center;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        border-radius: 3px;

        img {
          margin-left: 8px;
          height: 11px;
        }
      }
    }
  }
}
.top {
  width: 100%;
  text-align: center;
  color: #991403;
  font-weight: 600 !important;
  background-color: #ffefe2;
  padding-top: 45px;
  h1 {
    color: #991403;
    font-size: 30px;
    padding-bottom: 15px;
  }
}
.bc-yellow {
  background: #fdf1de;
}
.sd-template-two {
  height: 480px;
  padding: 20px 60px 60px 60px;
  overflow: hidden;
  display: flex;
  font-family: "Proxima Nova Alt", sans-serif;
  background-color: #ffefe2;

  .sd-box-one {
    padding: 35px;
    padding-bottom: 20px;
    width: 23%;
    margin: 1%;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    .sd-box-one-content {
      height: 75%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      h2 {
        color: black;
        font-weight: 600;
        font-size: 20px;
        margin: 0px;
        text-align: center;
      }
      p {
        line-height: 19px;
        font-size: 15px;
        color: #181818;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4; /* number of lines to show */
        -webkit-box-orient: vertical;
      }
      h6 {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        white-space: nowrap;
        padding: 5px 0 10px;
        font-size: 15px;
        font-weight: 600;
        color: #991403;
        margin-bottom: 0px;
        cursor: pointer;

        img {
          height: 11px;
          margin-left: 5px;
          // margin-top: 2px;
          // filter: invert(1);
        }
      }
      .c-white {
        color: white;
      }
      .c-18 {
        color: #181818;
      }
      button {
        border: none;
        font-size: 14px;
        padding: 6px 13px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        align-content: center;
        outline: none;
        white-space: nowrap;
        border-radius: 3px;
        img {
          height: 11px;
          margin-left: 5px;
        }
        &:hover {
          background-color: #ef9e1f;
          img {
            filter: brightness(100);
          }
        }
      }
      .sd-c-white {
        color: white;
      }
      .sd-c-voilet {
        color: #2f3293;
      }
      .sd-c-orange {
        color: #991403;
      }
      .sd-b-white {
        background-color: white;
      }
      .sd-b-orange {
        background-color: #991403;
        border-radius: 10px;

        img {
          height: 20px;
        }
      }
    }

    .sd-box-one-image {
      width: 100%;
      height: 25%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      .sd-logo-img {
        height: 65px;
        margin-bottom: 16px;
      }
      .sd-size-one {
        height: 68px;
      }
      .sd-size-two {
        height: 55px;
      }
      .sd-size-three {
        height: 62px;
      }
      .sd-size-four {
        height: 65px;
      }
    }
  }
  .sd-first-color {
    background-color: #fde6d2;
  }
  .sd-second-color {
    background-color: #facda6;
  }
  .sd-third-color {
    background-color: #991403;
  }
  .sd-fourth-color {
    background-color: #2f3293;
  }
}
.sd-template-three {
  height: 500px;
  padding: 60px 60px 50px 60px;
  display: flex;
  justify-content: space-evenly;
  font-family: "Proxima Nova Alt", sans-serif;
  background-image: url("../../Assets/kanipakam-temple-home-page/blue-background.svg");
  background-position: 90%; /* Center the image */
  background-position-x: 30px;
  background-position-y: 30px;
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: 90%; /* Resize the background image to cover the entire container */

  .sd-t3-one {
    width: 27%;
    height: 100%;
    img {
      max-height: 100%;
    }
  }
  .sd-t3-two {
    width: 27%;
    .sd-box-one {
      padding: 25px;
      width: 100%;
      margin: 1%;
      background: #ffffff 0% 0% no-repeat padding-box;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      border: 1px solid #707070;
      border-radius: 15px;
      .sd-box-one-content {
        height: 75%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        h2 {
          color: black;
          font-weight: 600;
          font-size: 20px;
          margin: 0px;
          text-align: center;
          margin-bottom: 10px !important;
        }
        p {
          line-height: 19px;
          font-size: 15px;
          color: #181818;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 5; /* number of lines to show */
          -webkit-box-orient: vertical;
        }
        h6 {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          white-space: nowrap;
          padding: 5px 0 10px;
          font-size: 15px;
          font-weight: 600;
          color: #991403;
          margin-bottom: 0px;
          cursor: pointer;

          img {
            height: 11px;
            margin-left: 5px;
            // margin-top: 2px;
            // filter: invert(1);
          }
        }
        .c-white {
          color: white;
        }
        .c-18 {
          color: #181818;
        }
        button {
          border: none;
          font-size: 14px;
          padding: 6px 13px;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 600;
          align-content: center;
          outline: none;
          white-space: nowrap;
          border-radius: 3px;
          img {
            height: 11px;
            margin-left: 5px;
          }
          &:hover {
            background-color: #ef9e1f;
            img {
              filter: brightness(100);
            }
          }
        }
        .sd-c-white {
          color: white;
        }
        .sd-c-voilet {
          color: #2f3293;
        }
        .sd-c-orange {
          color: #991403;
        }
        .sd-b-white {
          background-color: white;
        }
        .sd-b-orange {
          background-color: #991403;
          border-radius: 10px;

          img {
            height: 20px;
          }
        }
      }

      .sd-box-one-image {
        width: 100%;
        height: 25%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .sd-logo-img {
          margin-bottom: 16px;
        }
        .sd-size-one {
          height: 68px;
        }
        .sd-size-two {
          height: 55px;
        }
        .sd-size-three {
          height: 62px;
        }
        .sd-size-four {
          width: 100%;
        }
      }
    }
  }
  .sd-t3-three {
    width: 27%;
    .sd-box-one {
      padding: 25px;
      width: 100%;
      margin: 1%;
      background: #ffffff 0% 0% no-repeat padding-box;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      border: 1px solid #707070;
      border-radius: 15px;
      .sd-box-one-content {
        height: 75%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        h2 {
          color: black;
          font-weight: 600;
          font-size: 20px;
          margin: 0px;
          text-align: center;
          margin-bottom: 10px !important;
        }
        p {
          line-height: 19px;
          font-size: 15px;
          color: #181818;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 5; /* number of lines to show */
          -webkit-box-orient: vertical;
        }
        h6 {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          white-space: nowrap;
          padding: 5px 0 10px;
          font-size: 15px;
          font-weight: 600;
          color: #991403;
          margin-bottom: 0px;
          cursor: pointer;

          img {
            height: 11px;
            margin-left: 5px;
            // margin-top: 2px;
            // filter: invert(1);
          }
        }
        .c-white {
          color: white;
        }
        .c-18 {
          color: #181818;
        }
        button {
          border: none;
          font-size: 14px;
          padding: 6px 13px;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 600;
          align-content: center;
          outline: none;
          white-space: nowrap;
          border-radius: 3px;
          img {
            height: 11px;
            margin-left: 5px;
          }
          &:hover {
            background-color: #ef9e1f;
            img {
              filter: brightness(100);
            }
          }
        }
        .sd-c-white {
          color: white;
        }
        .sd-c-voilet {
          color: #2f3293;
        }
        .sd-c-orange {
          color: #991403;
        }
        .sd-b-white {
          background-color: white;
        }
        .sd-b-orange {
          background-color: #991403;
          border-radius: 10px;

          img {
            height: 20px;
          }
        }
      }

      .sd-box-one-image {
        width: 100%;
        height: 25%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .sd-logo-img {
          margin-bottom: 16px;
        }
        .sd-size-one {
          height: 68px;
          width: 100%;
        }
        .sd-size-two {
          height: 55px;
        }
        .sd-size-three {
          height: 62px;
        }
        .sd-size-four {
          // height: 65px;
          width: 100%;
        }
      }
    }
  }
}
.sd-template-four {
  height: 600px;
  padding: 60px 60px 50px 60px;
  display: flex;
  justify-content: space-between;
  font-family: "Proxima Nova Alt", sans-serif;
  background: #fdf1de;
  border-radius: 15px 15px 0px 0px;

  .sd-t4-one {
    width: 31%;
    height: calc(100% - 50px);
    background-color: white;
    border-radius: 15px;
    .sd-t4-one-start {
      background-color: #991403;
      color: white;
      text-align: center;
      padding: 10px 20px;
      border-radius: 15px 15px 0px 0px;
      display: flex;
      justify-content: space-between;
      span {
        font-size: 16px;
      }
      img {
        height: 25px;
      }
    }
    .sd-t4-one-middle {
      background-color: #ef9e1f;
      color: white;
      text-align: center;
      padding: 10px 20px;
      border-radius: 15px 15px 0px 0px;
      display: flex;
      justify-content: space-between;
      span {
        font-size: 16px;
      }
      img {
        height: 25px;
      }
    }
    .sd-t4-calender {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 45px;
      padding: 5px 20px;
      background-color: #f5f5f5;
      img {
        height: 20px;
      }
    }
    .element-panchangam-wrapper {
      padding: 0px 15px 10px 15px;
      height: calc(100% - 110px);
      flex-direction: column;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      .element-panchangam {
        display: flex;
        justify-content: space-between;
        padding: 12px 0px;
        border-bottom: 1px solid #707070;

        .w-100 {
          width: 100% !important;
        }
        &:last-child {
          border-bottom: none;
          div {
            width: 50% !important;
          }
        }

        div {
          width: 35%;
          display: flex;
          flex-direction: column;
          border-right: 1px solid #707070;
          padding-left: 5px;
          &:last-child {
            border: none;
          }
          &:first-child {
            padding-left: 0px !important;
            width: 25%;
          }
          p {
            margin-bottom: 0px;
            text-align: left;
            font-size: 12px;
          }
          h5 {
            text-align: left;
            font-size: 14px;
          }
        }
      }
    }
    .sd-t4-one-end {
      background-color: #991403;
      color: white;
      text-align: center;
      border-radius: 15px 15px 0px 0px;
      display: flex;
      justify-content: space-between;
      height: 45px;
      span {
        padding: 10px 20px;
        font-size: 16px;
        width: 33%;
        border-right: 1px solid #b54233 !important;
        &:last-child {
          border-right: none !important;
        }
        &:hover {
          color: #ef9e1f;
          cursor: pointer;
        }
      }
      .span-active {
        border-bottom: 4px solid #ef9e1f;
      }
      img {
        height: 25px;
      }
    }
    .sd-t4-one-end-d {
      display: flex;
      height: calc(100% - 50px);
      flex-direction: column;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      .element-date {
        display: flex;
        padding: 10px 20px 0px 20px;
        div {
          width: 50px;
          text-align: center;
          border-radius: 10px;
          margin-right: 10px;
          background-color: #ffe9ca;
          padding: 5px;
          height: 45px;
          font-size: 10px;
          span {
            font-size: 17px;
          }
        }
        p {
          width: calc(100% - 50px);

          margin-bottom: 0px;
          font-size: 12px;
        }
      }
    }
    .sd-t4-one-middle-b {
      height: 50px;
    }
    .sd-t4-one-end-m {
      display: flex;
      height: calc(100% - 110px);
      flex-direction: column;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      .element-date-m {
        margin: 10px 0px 5px 0px;
        padding: 0pc 20px;
        div {
          text-align: center;
          border-radius: 10px;
          margin-right: 10px;
          border: 1px solid grey;
          padding: 5px;
          width: fit-content;
          font-size: 11px;
          padding: 2px 8px;
        }
        p {
          width: 100%;
          margin: 2px 0px 0px 3px;

          font-size: 14px;
          color: #181818;
        }
      }
    }
  }
}
.sd-template-five {
  padding: 0 60px 30px;
  display: flex;
  flex-direction: column;
  font-family: "Proxima Nova Alt", sans-serif;

  h2 {
    align-self: center;
    margin-bottom: 5px;
    color: #991403;
    font-size: 36px;
    font-weight: 400;
  }
  .item1 {
    grid-area: one;
  }
  .item2 {
    grid-area: two;
  }
  .item3 {
    grid-area: three;
  }
  .item4 {
    grid-area: four;
  }
  .item5 {
    grid-area: five;
    height: 100%;
  }
  .item6 {
    grid-area: six;
    height: 100%;
  }

  .sd-photo-grid {
    width: 100%;
    height: 100%;
    display: grid;
    margin-top: 20px;
    grid-template-areas:
      "one one two three"
      "one one five six"
      "four four five six";
    gap: 20px;
    margin-bottom: 20px;

    div {
      display: flex;
      justify-content: space-between;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .sd-photo-grid-full {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    place-content: center;
    div {
      width: 22.5%;
      margin: 13px;

      .__react_modal_image__modal_container {
        width: 100vw;
        margin: 0px;
        .__react_modal_image__header {
          background-color: transparent;
          width: 98%;
          .__react_modal_image__caption {
            display: none;
          }
          // display: none;
        }
        .__react_modal_image__modal_content {
          width: 100vw;
          div {
            img {
              width: 70%;
              height: auto !important;
              border-radius: 3px;
            }
          }
        }
      }
      img {
        border-radius: 3px;
        height: 200px;
        width: 100%;
      }
    }
  }
  button {
    margin-top: 30px;
    border: none;
    background-color: #a41503;
    color: white;
    font-size: 14px;
    padding: 6px 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    align-self: center;
    white-space: nowrap;
    border-radius: 10px;
    img {
      height: 18px !important;
      margin-left: 5px;
    }
  }
  h4 {
    font-weight: 400;
    font-size: 20px;
  }
}
.sd-template-six {
  padding: 60px 60px 50px 60px;
  display: flex;
  width: 100%;
  height: 550px;
  .sd-t6-one {
    width: 47%;
    img {
      width: 100%;
      max-height: 100%;
    }
  }
  .sd-t6-two {
    width: 59%;
    padding-left: 30px;
    text-align: center;
    padding-top: 10px;
    div {
      h1 {
        font-size: 30px;
        color: #991403;
        margin-bottom: 15px !important;
      }
      p {
        font-size: 15px;
        margin-top: 30px;
        padding: 20px;
        padding-bottom: 0px !important;
        line-height: 20px;
        color: #171717;
      }
      h5 {
        font-weight: 400 !important;
        font-size: 13.5px;
        line-height: 18px;
        color: #171717;
        padding: 10px 20px;
      }
      .temples {
        display: flex;
        justify-content: center;
        margin-top: 15px;
        div {
          margin-right: 10px;
          padding-right: 20px;
          border-right: 1px solid #cbcbcb;
          &:last-child {
            border-right: none;
          }
          img {
            padding-right: 5px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1450px) {
  .sd-template-one {
    .sd-t1-slider {
      .slick-slider {
        .slick-dots {
          bottom: -20px;
          li {
            margin: 0 1px;
            color: #a41503;
            button {
              opacity: 1;
              &::before {
                color: #a41503;
                font-size: 10px;

                opacity: 1;
              }
            }
          }
          .slick-active {
            button {
              &::before {
                color: #ef9e1f;
              }
            }
          }
        }
      }
    }
    .sd-t1-bottom {
      .sd-orange {
        font-size: 15.5px;
      }
    }
  }
  .sd-template-two {
    height: 450px;
  }
  .sd-template-three {
    height: 460px;
    .sd-t3-two {
      .sd-box-one {
        padding: 15px 15px;
        .sd-box-one-content {
        }
      }
    }
    .sd-t3-three {
      .sd-box-one {
        padding: 15px 15px;
      }
    }
  }
  .sd-template-five {
    .sd-photo-grid {
      div {
        margin-bottom: 3.5vh;
      }
      // img {
      //   width: 23.5%;

      //   // margin-bottom: 20px;
      // }
    }
  }
  .sd-template-six {
    height: auto;
    .sd-t6-one {
      img {
        height: auto;
        max-height: auto !important;
      }
    }
    .sd-t6-two {
      div {
        h1 {
          font-size: 30px;
          color: #991403;
          margin-bottom: 10px;
        }
        p {
          font-size: 16px;
          margin-top: 30px;
          padding: 20px;
          color: #171717;
        }
        h5 {
          font-size: 14px;
          color: #171717;
        }
        .temples {
          div {
            img {
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1000px) and (min-width: 900px) {
  .sd-template-four {
    height: 1000px !important;
    flex-wrap: wrap;
    justify-content: center;

    .sd-t4-one {
      height: 420px !important;
      &:first-child {
        margin-right: 30px !important;
        margin-bottom: 30px;
      }
      width: 45% !important;
      .element-panchangam-wrapper {
        .element-panchangam {
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) and (min-width: 900px) {
  .sd-template-one {
    .sd-t1-slider {
      .slick-slider {
        .slick-dots {
          bottom: -20px;
        }
      }
    }
    .sd-t1-bottom {
      .sd-orange {
        width: 14%;
        font-size: 13px;
        img {
          height: 20px;
          margin-right: 5px !important;
        }
      }
      .sd-marquee-text {
        width: 75%;
        font-size: 15px;
      }
      div {
        button {
          font-size: 14px;
          padding: 6px 13px;
          img {
            height: 11px;
            margin-left: 8px;
          }
        }
      }
    }
  }
  .sd-template-two {
    height: 430px;
    .sd-box-one {
      padding: 25px;
      padding-top: 35px;
      padding-bottom: 20px;
      .sd-box-one-content {
        p {
          // margin-bottom: 0px;
          font-size: 13px;
        }
        button {
          font-size: 12px;
          padding: 6px 13px;
          img {
            height: 8px;
            margin-left: 7px;
          }
        }
        h2 {
          font-size: 17px;
        }
      }
      .sd-box-one-image {
        .sd-logo-img {
          height: 50px;
        }
      }
    }
  }
  .sd-template-three {
    height: 400px;
    .sd-t3-two {
      .sd-box-one {
        padding: 10px 10px 20px 10px;
        padding-top: 10px;
        .sd-box-one-content {
          p {
            // margin-bottom: 0px;
            font-size: 13px;
          }
          button {
            font-size: 12px;
            padding: 6px 13px;
            img {
              height: 8px;
              margin-left: 7px;
            }
          }
          h2 {
            font-size: 17px;
          }
        }
        .sd-box-one-image {
          .sd-logo-img {
            height: auto;
          }
        }
      }
    }
    .sd-t3-three {
      .sd-box-one {
        padding: 10px 10px 20px 10px;

        .sd-box-one-content {
          p {
            // margin-bottom: 0px;
            font-size: 13px;
          }
          button {
            font-size: 12px;
            padding: 6px 13px;
            img {
              height: 8px;
              margin-left: 7px;
            }
          }
          h2 {
            font-size: 17px;
          }
        }
        .sd-box-one-image {
          .sd-logo-img {
            height: auto;
          }
        }
      }
    }
  }
  .sd-template-four {
    height: 570px;
    .sd-t4-one {
      .sd-t4-one-end {
        span {
          font-size: 14px;
          padding: 10px;
        }
      }
      .element-panchangam-wrapper {
        .element-panchangam {
          div {
            p {
              font-size: 11px !important;
            }
            h5 {
              font-size: 13px !important;
            }
          }
        }
      }
      .sd-t4-one-end-d {
        .element-date {
          div {
            width: 45px;
          }
          p {
            font-size: 12px;
          }
        }
      }
      .sd-t4-one-end-m {
        .element-date-m {
          p {
            font-size: 13px;
          }
        }
      }
    }
  }
  .sd-template-five {
    button {
      font-size: 12px;
      padding: 6px 13px;
      margin-top: 10px;
      img {
        margin: 2px;

        height: 8px;
        margin-left: 7px;
      }
    }
    .sd-photo-grid {
      height: 90%;
      div {
        // flex-wrap: wrap;
        margin-bottom: 0.6vh;
        img {
          margin: 5px;

          // height: 42%;
          // width: 46%;
        }
      }
    }
  }
  .sd-template-six {
    .sd-t6-two {
      div {
        h1 {
          font-size: 30px;
          color: #991403;
          margin-bottom: 10px;
        }
        p {
          font-size: 1.2vw;
          margin-top: 10px;
          padding: 15px;
          color: #171717;
        }
        h5 {
          font-size: 1.1vw;
          line-height: 14px;

          color: #171717;
        }
        .temples {
          div {
            img {
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 900px) and (min-width: 650px) {
  .sd-template-one {
    .sd-t1-slider {
      padding: 15px;
      .slick-slider {
        .slick-dots {
          bottom: -13px !important;
          li {
            margin: 0 1px;
            color: #a41503;
            width: 15px;
            height: 15px;
            button {
              font-size: 5px;
              opacity: 1;
              &::before {
                font-size: 8px;

                color: #a41503;
                opacity: 1;
              }
            }
          }
          .slick-active {
            button {
              &::before {
                color: #ef9e1f;
              }
            }
          }
        }
      }
    }
    .sd-t1-bottom {
      height: 40px;
      padding-left: 30px;
      padding-right: 30px;
      .sd-orange {
        width: 14%;
        font-size: 10px;
        img {
          height: 13px;
          margin-right: 5px !important;
        }
      }
      .sd-marquee-text {
        width: 70%;
        font-size: 11px;
      }
      div {
        button {
          padding: 1px 8px;

          font-size: 10px;
          img {
            height: 8px;
            margin-left: 5px;
          }
        }
      }
    }
  }
  .sd-template-two {
    height: 270px;
    padding: 30px;
    .sd-box-one {
      padding: 18px;
      .sd-box-one-content {
        p {
          margin-bottom: 5px;
          font-size: 10px;
          line-height: 1.3;
        }
        .sd-b-orange {
          padding: 4px 8px;

          img {
            height: 12px;
          }
        }
        button {
          font-size: 10px;
          padding: 1px 8px;
          img {
            height: 8px;
            margin-left: 5px;
          }
        }
        h2 {
          font-size: 13px;
        }
      }
      .sd-box-one-image {
        .sd-logo-img {
          height: 30px;
          margin-bottom: 0px;
        }
      }
    }
  }
  .sd-template-three {
    height: 330px;
    .sd-t3-one {
      width: 35%;
    }
    .sd-t3-two {
      .sd-box-one {
        padding: 8px;
        .sd-box-one-content {
          .sd-b-orange {
            padding: 4px 8px;

            img {
              height: 12px;
            }
          }
          p {
            font-size: 10px;
            line-height: 1.3;
          }
          button {
            font-size: 10px;
            padding: 1px 8px;
            img {
              height: 8px;
              margin-left: 5px;
            }
          }
          h2 {
            font-size: 13px;
            margin-top: 10px !important;
            margin-bottom: 5px !important;
          }
        }
        .sd-box-one-image {
          .sd-logo-img {
            margin-bottom: 0px;
          }
        }
      }
    }
    .sd-t3-three {
      .sd-box-one {
        padding: 8px;
        .sd-box-one-content {
          p {
            font-size: 10px;
            line-height: 1.3;
          }
          button {
            font-size: 10px;
            padding: 1px 8px;
            img {
              height: 8px;
              margin-left: 5px;
            }
          }
          h2 {
            font-size: 13px;
            margin-top: 10px !important;
            margin-bottom: 5px !important;
          }
          .sd-b-orange {
            padding: 4px 8px;

            img {
              height: 12px;
            }
          }
        }
        .sd-box-one-image {
          .sd-logo-img {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
  .sd-template-four {
    height: 1000px !important;
    flex-wrap: wrap;
    padding: 30px !important;
    justify-content: center;

    .sd-t4-one {
      height: 420px !important;
      &:first-child {
        margin-right: 30px !important;
        margin-bottom: 30px;
      }
      width: 47% !important;
      .element-panchangam-wrapper {
        .element-panchangam {
        }
      }
    }
  }
  .sd-template-five {
    .sd-photo-grid {
      div {
        margin-bottom: 10px;
      }
    }
    .sd-photo-grid-full {
      div {
        width: 45%;
      }
    }

    button {
      font-size: 10px;
      border: none;
      padding: 1px 8px;
      margin-top: 10px;
      img {
        height: 8px;
        margin-left: 5px;
      }
    }
  }
  .sd-template-six {
    height: auto;
    padding: 30px;
    flex-direction: column-reverse;
    .sd-t6-one {
      width: 100%;
      text-align: center;
      img {
        width: 85%;
        align-self: center;
      }
    }
    .sd-t6-two {
      width: 100%;
      padding-left: 0px;
    }
  }
}
@media screen and (max-width: 650px) {
  .sd-template-one {
    .sd-t1-slider {
      padding: 10px;
      padding-bottom: 20px;
      // padding: 6px 2px 12px 5px;
      .slick-slider {
        .slick-dots {
          bottom: -18px;
          li {
            margin: 0 0px;
            width: 15px;
            color: #a41503;
            button {
              opacity: 1;
              &::before {
                color: #a41503;
                opacity: 1;
                font-size: 8px;
              }
            }
          }
          .slick-active {
            button {
              opacity: 1;
              &::before {
                color: #ef9e1f;
                opacity: 1;
                font-size: 8px;
              }
            }
          }
        }
      }
    }
    .sd-t1-bottom {
      height: 40px;
      padding-left: 10px;
      padding-right: 10px;
      .sd-orange {
        width: 20%;
        font-size: 11px;
        padding-right: 3px;
        text-align: center;
        img {
          display: none;
        }
      }
      .sd-marquee-text {
        width: 55%;
        font-size: 12px;
      }
      div {
        width: 25%;
        button {
          font-size: 12px;
          padding: 2px 10px;
          img {
            height: 9px;
            margin-left: 5px;
            margin-top: 0px;
          }
        }
      }
    }
  }
  .sd-template-two {
    height: 1200px;
    padding: 30px;
    flex-wrap: wrap;
    justify-content: space-around;
    .sd-box-one {
      width: 100%;
      padding: 10px;
      padding-top: 20px;
      display: flex;
      .sd-box-one-content {
        padding-left: 25px;
        padding-right: 25px;

        p {
          margin-bottom: 10px;
          font-size: 14px;
          line-height: 15px;
        }
        button {
          font-size: 12px;
          padding: 2px 10px;
          img {
            height: 10px;
            margin-left: 7px;
          }
        }
        h2 {
          font-size: 16px;
          margin: 0px;
        }
        // border-left: 0.5px solid #dbdbdb;
      }
      .sd-box-one-image {
        height: 30%;
        .sd-logo-img {
          height: 60px;
          margin: 0px;
        }
      }
    }
  }
  .sd-template-three {
    height: auto;
    padding: 30px;
    display: flex;
    flex-direction: column;
    .sd-t3-one {
      width: 100%;
      margin-bottom: 25px;
      padding: 20px;
      img {
        width: 90% !important;
      }
    }
    .sd-t3-three {
      width: 100%;
      .sd-box-one {
        padding: 12px;
        .sd-box-one-content {
          .sd-b-orange {
            font-size: 12px;
          }
          h2 {
            font-size: 16px;
          }
          p {
            font-size: 13px;
          }
        }
      }
    }
    .sd-t3-two {
      width: 100%;
      .sd-box-one {
        padding: 12px;
        .sd-box-one-content {
          .sd-b-orange {
            font-size: 12px;
          }
          h2 {
            font-size: 16px;
          }
          p {
            font-size: 13px;
          }
        }
      }
    }
  }
  .sd-template-four {
    height: 1170px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    .sd-t4-one {
      width: 100%;
      margin-bottom: 20px;
      max-height: 350px;
    }
  }
  .sd-template-five {
    height: auto;
    padding: 20px;
    h2 {
      font-size: 36px;
      // margin-bottom: 20px;
    }
    .sd-photo-grid {
      height: 90%;
      display: flex;
      flex-wrap: wrap;
      img {
        width: 100%;
      }
      div {
        flex-wrap: wrap;
        margin-bottom: 0vh;

        img {
          margin: 5px;
          margin-bottom: 10px;

          height: 41%;
          width: 46.5%;
        }
      }
    }
    .sd-photo-grid-full {
      div {
        width: 90%;
      }
    }
    button {
      font-size: 12px;
      padding: 2px 10px;
      margin-top: 10px;
      img {
        height: 10px;
        margin-left: 5px;
      }
    }
  }
  .sd-template-six {
    padding: 20px;
    height: auto;
    flex-direction: column-reverse;
    .sd-t6-one {
      width: 100%;
      text-align: center;
      img {
        width: 80%;
        align-self: center;
      }
    }
    .sd-t6-two {
      width: 100%;
      padding: 0px !important;
      div {
        p {
          margin-bottom: 0px;
          margin-top: 10px;
          font-size: 14px;
          line-height: 20px;
        }
        .temples {
          flex-direction: column;
          div {
            margin-bottom: 10px;
            border-right: 0px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 100px) and (max-width: 350px) {
  .sd-template-one {
    .sd-t1-slider {
    }
    .sd-t1-bottom {
      .sd-marquee-text {
        width: 50%;
      }
      div {
        button {
          font-size: 12px;
          padding: 2px 10px;
          img {
            height: 9px;
            margin-left: 5px;
            margin-top: 0px;
          }
        }
      }
    }
  }
  .sd-template-three {
    height: 500px;
    .sd-t3-inner-div {
      .sd-t3-box-left {
      }
      .sd-t3-box-right {
        align-items: center;
        Iframe {
          // height: 150px;
          // width: 250px;
        }
        img {
          height: 150px;
          width: 250px;
        }
      }
    }
  }
  .sd-template-four {
    margin-bottom: 30px;
    .sd-t4-box-left {
      height: 400px;
    }
    .sd-t4-box-right {
    }
  }
  .sd-template-five {
    // height: 500px;
    padding: 20px;
    h2 {
      font-size: 36px;
      // margin-bottom: 20px;
    }
    .sd-photo-grid {
      height: 90%;
      div {
        flex-wrap: wrap;
        margin-bottom: 0vh;

        img {
          margin: 5px;
          margin-bottom: 1vh;

          height: 41%;
          width: 46%;
        }
      }
    }
    button {
      font-size: 12px;
      padding: 2px 10px;
      margin-top: 10px;
      img {
        height: 10px;
        margin-left: 5px;
      }
    }
  }
}

@keyframes circleAnimation {
  0% {
    stroke-dashoffset: 75;
  }

  50% {
    stroke-dashoffset: 280;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
